import { useState } from 'react';
import logo from './LOGO.svg'
import './Navbar.css'

function Navbar() {
    const [clicked, setClicked] = useState(false);
    function handleClick() {
        setClicked(!clicked);
    }

    return <>
        <nav>
            <div className="logo">
                <a href="#home"><img className="logoImage" src={logo} alt="IIIT Bhopal" /></a>
            </div>
            <div className="navbar__ pill">
                <div className="navbarContainer">
                    <a className="navbarLinks" href="#home" alt="HOME">HOME</a>
                    <a className="navbarLinks" href="#about" alt="ABOUT">ABOUT</a>
                    <a className="navbarLinks" href="#events" alt="EVENTS">EVENTS</a>
                    <a className="navbarLinks" href="#team" alt="TEAM">TEAM</a>
                </div>
            </div>
            <a className="button" href="#contact" alt="CONTACT">CONTACT</a>
            <div className={(clicked) ? "menu active" : "menu"}>
                <a href="#1" className="crossMark" onClick={handleClick}>
                    {(clicked) ? <i class="fa-solid fa-xmark"></i> :
                        null}
                </a>
                <ul>
                    <li><a className="hover-underline-animation" onClick={handleClick} href="#home">HOME</a></li>
                    <li><a className="hover-underline-animation" onClick={handleClick} href="#about">ABOUT</a></li>
                    <li><a className="hover-underline-animation" onClick={handleClick} href="#events">EVENTS</a></li>
                    <li><a className="hover-underline-animation" onClick={handleClick} href="#team">TEAM</a></li>
                    <li><a className="hover-underline-animation" onClick={handleClick} href="#contact">CONTACT</a></li>
                </ul>
            </div>
            <div className="toggle">
                <a onClick={handleClick}>
                    {(!clicked) ? <i class="fa fa-bars"></i> : null}
                </a>
            </div>

        </nav>

    </>
}

export default Navbar;