import React from "react";
import './Team.css';
import Card from "./Card";
import gkbharti from './images/GK_bharti.jpg';
import nishasinghal from './images/Nisha_singhal.jpg';
import afreenkhursheed from './images/Afreen_khursheed.jpg';
import sannidhyaawasthi from './images/Sannidhya_awasthi.jpg';
import sanskarprasad from './images/Sanskar_prasad.jpg';
import riyanshchauhan from './images/Riyansh_chauhan.jpg';
import shouviksutar from './images/Shouvik_sutar.jpg';
import rahulsingh from './images/Rahul_singh.jpg';
import arpitraj from './images/Arpit_raj.jpg';
import satwiksingh from './images/Satwik_singh.jpg';
import pareeshipaharia from './images/Pareeshi_paharia.jpg';
import arpitchauhan from './images/Arpit_chauhan.jpg';
import abhisheksingh from './images/Abhishek_singh.jpg'
import anjaniKumar from './images/Anjani_Kumar.jpg'

function Team() {
    return <>
        <div id="team">
            <center>
                <div class="justify-content-center">
                    <h1 className="heading">Our Team</h1>
                    <hr style={{ width: "20%", height: "1px", color: "black" }} />
                </div>
                <br /><br />
                <h2>Faculty Members</h2>
                <div class="container reveal fade-bottom">
                    <div class="row">
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Dr. Gaurav Kumar Bharti"
                                role="Branch Counsellor"
                                src={gkbharti} />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Dr. Nisha Singhal"
                                role="Faculty Advisor"
                                src={nishasinghal} /></div>
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Dr. Afreen Khursheed"
                                role="Faculty Advisor"
                                src={afreenkhursheed} />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Dr. Anjani Kumar"
                                role="Faculty Advisor"
                                src={anjaniKumar} />
                        </div>
                    </div>
                </div>

                <br /><br /><br /><br /><br />
                <h2>Student Executives</h2>
                <div class="container reveal fade-bottom" >
                    <div class="row">
                        <div className="col-lg-4 col-sm-12"> <Card
                            name="Sannidhya Awasthi"
                            role="Chairperson"
                            src={sannidhyaawasthi} />
                        </div>
                        <div className="col-lg-4 col-sm-6"><Card
                            name="Sanskarkumar Prasad"
                            role="Vice Chairperson"
                            src={sanskarprasad} /></div>
                        <div className="col-lg-4 col-sm-6"><Card
                            name="Riyansh Chauhan"
                            role="Secretary"
                            src={riyanshchauhan} /></div>
                    </div>
                </div>
                {/* <br /> */}
                <div class="container reveal fade-bottom">
                    <div class="row">
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Arpit Raj"
                                role="Treasurer"
                                src={arpitraj} />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Shouvik Sutar"
                                role="Web Master-I"
                                src={shouviksutar} />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Rahul Singh Chauhan"
                                role="Web Master-II"
                                src={rahulsingh} />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <Card
                                name="Satwik Singh"
                                role="Design Coordinator"
                                src={satwiksingh} />
                        </div>
                    </div>
                    {/* <br /> */}
                </div>
                <div class="container reveal fade-bottom">
                    <div class="row">
                        {/* <div className="col-lg-3 col-sm-6 disableAtCondition" >
                        </div> */}
                        <div className="col-lg-4 col-sm-12" >
                            <Card
                                name="Abhishek Singh"
                                role="Publicity Coordinator"
                                src={abhisheksingh} />
                        </div>
                        <div className="col-lg-4 col-sm-6" >
                            <Card
                                name="Pareeshi Paharia"
                                role="Publicity Coordinator"
                                src={pareeshipaharia} />
                        </div>
                       
                        <div className="col-lg-4 col-sm-6">
                            <Card
                                name="Arpit Chauhan"
                                role="Social Media Coordinator"
                                src={arpitchauhan} />
                        </div>

                        {/* <div className="col-lg-3 col-sm-6 disableAtCondition" >
                        </div> */}
                    </div>
                </div>
            </center >
        </div >

    </>
}

export default Team;