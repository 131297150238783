import React from 'react';
import "./styles.css";
import LogoWhite from "./IEEESBwhite-removebg-preview (1).png"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {Icon} from "leaflet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFacebook,faLinkedin, faInstagram} from '@fortawesome/free-brands-svg-icons';








export default function Contact(){
    const customIcon = new Icon(
        {
            iconUrl: "https://cdn-icons-png.flaticon.com/128/684/684908.png",
            iconSize :[38 , 38]
        }
    )
    return (
        
        <div id="contact" className=' contact'>
          <div className='row-1'>                 
                <div className='logo-alpha'>
                    <img src={LogoWhite} alt="loading"></img>
                 </div>
                   
                    <div className='combined'>
                    <div className='Explore'>
                    <div className='links-1'>
                      <a href='#home'>Explore</a><br></br>
                      <a href='#about'>About Us</a> <br></br>
                      <a href='#events'>Events</a><br></br>
                      <a href='#team'>Our Team</a>
                      </div>

                    </div>
                    <div className='useful-links'>
                    <div className='links-2'>
                    Useful Links<br></br>
                    <a href='https://www.ieee.org/membership/join/index.html' target="_blank" rel="noreferrer">Join IEEE</a><br></br>
                    <a href='https://www.ieee.org/membership-catalog/index.html?srchProdType=Special%20Interest%20Groups&searchType=prodType' target="_blank" rel="noreferrer">Membership</a><br></br>
                    <a href='https://mga.ieee.org/awards/mga-awards-and-recognition-program' target="_blank" rel="noreferrer">Awards and Recognition</a><br></br>
                    <a href='https://cis.ieee.org/activities/membership-activities/travel-grants' target="_blank" rel="noreferrer">Students Travel Grant</a><br></br>
                    </div>
                    </div>
                    </div>
                    </div>
             <div className='row-2'>
             <div className='copyright'>
                <p>Copyright © 2023 
                   IEEE IIIT Bhopal Student Branch <br></br>
                   All Rights Reserved</p>
                   </div>
                   <div className='map'>
                   <div className='headL'>
                      <h1><span className='our'>Our</span> Location</h1>
                    </div>
                <div className='map-wrap'>
                   
                    <MapContainer center={[23.21785, 77.40858]} zoom={13}>
                        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
                        <Marker position={[23.21785, 77.40858]} icon={customIcon}>
                            <Popup className='popup'><h2>IIIT BHOPAL</h2></Popup>
                        </Marker>
                    </MapContainer>
                </div>
                </div>
                    <div className='contact-numbers common'>
                    <div className='links-3'>                    
                    ieeeiiitbhopal@gmail.com<br></br>
                    </div>
                    </div>
                    </div>
                <div  className='row-3'>
                   <div className='designation common'>
                    Designed and Developed by<br></br>
                    Satwik Singh<br></br>
                    Shouvik Sutar<br></br>
                    Rahul Singh<br></br>
                    Arpit Raj
                    </div>
                    <div className='social-media'>
                    <div className='icons'>
                        <FontAwesomeIcon icon={faSquareFacebook}  onClick={() => { window.open("https://www.facebook.com/profile.php?id=61551820298243&mibextid=ZbWKwL", '_blank') }} className='icon'/>
                        <FontAwesomeIcon icon={faInstagram} onClick={() => { window.open("https://www.instagram.com/ieee_iiitb/", '_blank') }} className='icon'/>
                        <FontAwesomeIcon icon={faLinkedin} onClick={() => { window.open("https://www.linkedin.com/company/ieee-iiitb/", '_blank') }} className='icon' />
                    </div>
                 </div>
               </div>
               </div>
    )

}