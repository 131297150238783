import React from "react";
import logo from './LOGO.svg'
import './join.css'


function Join() {
    return <div className="join reveal fade-bottom">
        <center>
            <img src={logo}  alt="IEEE IIIT Bhopal Logo" />
            <h2>IEEE IIIT Bhopal Student Branch is here to guide your innovation journey at every stage of your career.</h2>
            <div className="btn" onClick={() => { window.open("https://www.ieee.org/membership/join/index.html?WT.mc_id=hc_join", '_blank') }} style={{ margin: "2rem", width: "200px", height: "40px" }}>Join IEEE</div>
            <br /><br />
        </center>
    </div>
}

export default Join;