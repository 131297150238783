import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import ieeelogo from "./IEEE.svg";
import Logo from "./LOGO.svg";
import IIITlogo from "./IEEE1.png";
import "./styles.css";

export default function About() {
  return (
    <div id="about">
      <div className="container-fluid">
        <h1 className="heading">About Us</h1>
        <Row className="Row-1 reveal fade-bottom">
          <Col lg={6} sm={12}>
            <div className="logo-1 Logo">
              <center>
                <img src={ieeelogo} alt="loading"></img>
              </center>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="p-1">
              <p>
                IEEE (Institute of Electrical and Electronics Engineers) stands as a global beacon of technological advancement, dedicated to harnessing the power of innovation for the betterment of humanity. It's the world's largest community of technical professionals, passionately committed to pushing the boundaries of technology for the greater good.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="Row-2 reveal fade-bottom">
          <Col lg={6} sm={12} className="row-2-c1 order-1">
            <div className="p-2">
              <p>
                At IIIT Bhopal, we take immense pride in our association with
                IEEE. Our IEEE student branch serves as a vibrant hub of
                creativity, where we cultivate technological innovation and
                foster a culture of knowledge-sharing among our dedicated
                members.
              </p>
            </div>
          </Col>
          <Col lg={6} sm={12} className="row-2-c2">
            <div className="Logo l-2">
              <center>
                <img src={Logo} alt="loading"></img>
              </center>
            </div>
          </Col>
        </Row>
        <Row className="Row-3 reveal fade-bottom">
          <Col lg={6} sm={12}>
            <div className="Logo Logo-3">
               <center>
                <img src={IIITlogo} alt="loading"></img>
              </center>
            </div>
          </Col>
          <Col lg={6} className="para-3" sm={12}>
            <div className="p-3">
              <p>
                IIIT Bhopal, known far and wide for its excellence in computer
                science and technology education, is our home. Within its walls,
                we nurture a spirit of curiosity, exploration, and
                collaboration, all of which are essential elements of the IEEE
                community. Together, we strive to make a positive impact on the
                world through the limitless possibilities of technology.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
