import React from "react";
import './Query.css'

function Query() {

    return <>
        <div className="query">
            <div className="queryHeading">
                <h1 >Got a Query</h1>
                <h3>Contact us or drop a note!</h3>
            </div>
            <form>
                <div className="querycontainer">
                    <center>
                        <div className="row">
                            <div className="form-group col-lg-4">
                                <input type="text" className="form-control" name="titleText" aria-describedby="emailHelp" placeholder="Name" />
                                <textarea type="email" className="form-control" rows="5" name="Email" placeholder="Email"></textarea>
                            </div>
                            <div className="form-group col-lg-4">
                                <textarea type="text" className="form-control" rows="8" name="Comment" placeholder="Comment"></textarea>
                            </div>
                            <div className="col-md-4">
                                <div className="buttonDiv">
                                {/* <a className="btn-three btn-1" href="mailto:{email}?subject={subject}&body={body}">Send</a> */}

                                    <button className="btn-three btn-1">Send</button>
                                    <button className="btn-inactive">OR</button>
                                    <button className="btn-three btn-2">Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </center>
                </div>
            </form>

        </div>
    </>
}

export default Query;