import React from "react";


function Card(props) {
    return <>
            <div class="member">
                <div class="member-img">
                    <center>
                        <img src={props.src} class="img-fluid" alt="team_member" />
                    </center>
                </div>
                <div class="member-info">
                    <h4>{props.name}</h4>
                    <span>{props.role}</span>
                </div>
            </div>
    </>
}

export default Card;