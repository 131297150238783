import React from "react";
import Navbar from "./Navbar/Navbar";
import About from "./About";
import Home from "./Landing_page/home";
import Events from "./Event/Events";
// import UpcomingEvents from "./UpcomingEvents/UpcomingEvents";
import Team from "./Team/Team";
import Join from "./Join/Join";
import Contact from "./Contact";
import Query from "./Query/Query";

function App() {
  return <>
      <Navbar/>
      <Home />
      <About/>
      <Events/>
      {/* <UpcomingEvents/> */}
      <Team/>
      <Join/>
      <Query/>
      <Contact/>
  </>
}

export default App;
