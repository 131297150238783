import React, { useEffect, useState } from 'react';
import './home.css'; // Import your CSS file
import image1 from './output-onlinepngtools.png'
import image2 from './LOGO.svg';
import { TypeAnimation } from 'react-type-animation';

function WelcomeScreen() {
    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        // Add the 'show-welcome' class after a delay
        const timer1 = setTimeout(() => {
            setShowWelcome(true);
        }, 1000); // Adjust the delay time (in milliseconds) as needed

        return () => clearTimeout(timer1); // Clear the timer if the component unmounts
    }, []);
    const [showImage1, setShowImage1] = useState(true);

    useEffect(() => {
        // Automatically toggle images after 1 second on component mount
        const firstImageTimer = setTimeout(() => {
            setShowImage1(!showImage1);
        }, 1000);

        // Clear the timer after the first transition
        if (!showImage1) {
            clearTimeout(firstImageTimer);
        }

        return () => clearTimeout(firstImageTimer); // Clear the timer if the component unmounts
    }, [showImage1]);
    useEffect(() => {
        // Prevent scrolling
        const originalOverflowStyle = document.body.style.overflow;
        document.body.style.overflow = 'hidden';

        // Enable scrolling after 1 second
        const enableScrolling = setTimeout(() => {
            document.body.style.overflow = originalOverflowStyle;
        }, 1000);

        // Clear the timer and re-enable scrolling when the component unmounts
        return () => {
            clearTimeout(enableScrolling);
            document.body.style.overflow = originalOverflowStyle;
        };
    }, []);


    const [isShifted, setIsShifted] = useState(false);

    useEffect(() => {
        // Set a timer to shift the image to the right after a delay (e.g., 1 second)
        const shiftTimer = setTimeout(() => {
            setIsShifted(true);
        }, 3000);

        // Clear the timer when the component unmounts to avoid memory leaks
        return () => clearTimeout(shiftTimer);
    }, []);
    const [isShown, setIsShown] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        // Set a timeout to trigger the fade-in effect after 5 seconds (5000 milliseconds)
        const timeout = setTimeout(() => {
            setFadeIn(true);
        }, 4000);

        // Clear the timeout when the component unmounts to prevent any memory leaks
        return () => {
            clearTimeout(timeout);
        };
    }, []);
    return (
        <div className='home' class="home" id='home'>
            <div className={`welcome-screen ${showWelcome ? 'show-welcome' : ''}`}>
                <div className={`fade-in-container ${fadeIn ? 'fade-in' : ''}`}>
                    <div className="content">
                        <div className='one'>
                            The place where
                        
                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                                <TypeAnimation
                                    sequence={[
                                        'coding',
                                        2000,
                                        'networking',
                                        2000,
                                        'vibing',
                                        2000,
                                        'designing',
                                        2000,
                                        'everything',
                                        2000,
                                    ]}
                                    wrapper="span"
                                    speed={30}
                                    deletionSpeed={60}
                                    className='type'
                                    style={{textDecoration: 'underline', color: '#4678f9ff' }}
                                    repeat={Infinity}
                                />
                                <p className='two'>
                                    takes place.
                                </p>
                            </div>
                        </div>
                        <p className='home-text'>
                            Join our mailing list to be notifed about the latest updates on our branch, get information about events, and to be the first to know about all the happenings within IEEE!
                        </p>
                        <div class="data">
                            {/* <button type="button" class="btn btn-primary btn-lg big">Join IEEE IIIT BHOPAL →</button> */}
                            <button type="button" class="btn btn-primary btn-lg big"><a href='https://forms.gle/dpXNzcBhUc7XQkvj7' style={{color:"white",textDecoration:"none"}}>Register for Hack-O-Sprint 2.0</a></button>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <img src={showImage1 ? image1 : image2}
                        alt={showImage1 ? 'Image 1' : 'Image 2'}
                        className={`image${showImage1 ? 'fadeIn' : 'fadeOut'}`} class={`image ${isShifted ? 'shifted' : ''}`} />
                </div>

            </div>
        </div>
    );
}

export default WelcomeScreen;