import React from 'react';
import './Events.css';

import img1 from './images/IMG-20230920-WA0001.jpg';


// importing event images 
import nsd_1 from "./images/national-science-day/nsd1.jpg";
import main_nsd from "./images/national-science-day/main-nsd.jpg";
import nsd_2 from "./images/national-science-day/nsd-2.jpg";
import nsd_3 from "./images/national-science-day/nsd-3.jpg";

import hos_1 from './images/hack-o-sprint_1.0/IMG-20230921-WA0051.jpg';
import hos_2 from './images/hack-o-sprint_1.0/IMG-20230921-WA0057.jpg';
import hos_3 from './images/hack-o-sprint_1.0/IMG-20230921-WA0031.jpg';
import hos_4 from './images/hack-o-sprint_1.0/IMG-20230921-WA0047.jpg';
import hos_5 from './images/hack-o-sprint_1.0/IMG-20230921-WA0043.jpg';

import vw_1 from './images/vega-workshop-inauguration/IMG-20231115-WA0004.jpg';
import vw_2 from './images/vega-workshop-inauguration/IMG-20231115-WA0013.jpg';

import inaug_3 from './images/ieee_inauguration/photo_2023-11-19_12-22-32.jpg';
import inaug_1 from './images/ieee_inauguration/photo_2023-11-19_12-20-46.jpg';
import inaug_2 from './images/ieee_inauguration/photo_2023-11-19_12-22-27.jpg';


function Gallery() {
    return <div id="events" className='events'>
        <center>
            <h1 className="heading">Events</h1>
            <hr style={{ width: "20%", height: "1px", color: "black" }} />
        </center>
        <div className='slide-w'>
            <div className='slide'>
                <span>
                    <img src={main_nsd} alt="event_image" /> &#160;&#160;&#160;
                    <img src={nsd_1} alt="event_image" /> &#160;&#160;&#160;
                    <img src={nsd_2} alt="event_image" /> &#160;&#160;&#160;
                    <img src={nsd_3} alt="event_image" /> &#160;&#160;&#160;
                    <img src={img1} alt="event_image" /> &#160;&#160;&#160;
                    <img src={hos_1} alt="event_image" /> &#160;&#160;&#160; 
                    <img src={hos_2} alt="event_image" /> &#160;&#160;&#160;
                    <img src={hos_3} alt="event_image" /> &#160;&#160;&#160;
                    <img src={hos_4} alt="event_image" /> &#160;&#160;&#160;
                    <img src={hos_5} alt="event_image" /> &#160;&#160;&#160;
                    <img src={inaug_3} alt="event_image" /> &#160;&#160;&#160;
                    <img src={inaug_1} alt="event_image" /> &#160;&#160;&#160;
                    <img src={inaug_2} alt="event_image" /> &#160;&#160;&#160;
                    <img src={vw_1} alt="event_image" /> &#160;&#160;&#160;
                    <img src={vw_2} alt="event_image" /> &#160;&#160;&#160;
                    
                </span>
            </div>
        </div>
    </div>
}
export default Gallery;


// PREVIOUS VERSION CODE 
//  /* <div className='galleryCarousel'>
//             <center>
//                 <Carousel className='custom-carousel' interval={1000} controls={false}>
//                     <Carousel.Item className='custom-carousel-item' interval={1000}>
//                         <div className='carouselItem'>
//                             <img className='d-block' src={img1} alt='First slide' />
//                             <div className='imageCaptionBar'>
//                                 <p>21 Sept. 2023</p><br />
//                                 <p>Hack-O-Sprint 1.0.</p>
//                             </div>
//                         </div>
//                         <div className='subCarouselItem'>
//                             <Carousel interval={1000} controls={true}>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={hos_1} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={hos_2} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={hos_3} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={hos_4} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={hos_5} alt='First slide' />
//                                 </Carousel.Item>
//                             </Carousel>
//                         </div>
//                         <div className='text-container'>
//                             <p>21 Sept. 2023</p><br />
//                             <p>Hack-O-Sprint 1.0.</p>
//                             <div className='socialMedia'>
//                                 <img src={instagram} style={{ height: "40px", width: "40px" }} alt="instagram" />
//                                 <img src={linkedIn} style={{ height: "48px", width: "48px" }} alt="linkedIn" />
//                             </div>
//                         </div>
//                     </Carousel.Item>
//                     <Carousel.Item className='custom-carousel-item' interval={1000}>
//                         <div className='carouselItem'>
//                             <img className='d-block' src={img2} alt='Third slide' />
//                         </div>
//                         <div className='text-container'>
//                             <p>15 Sept. 2023</p><br />
//                             <p>Engineer's Day Celebration</p>
//                             <div className='socialMedia'>
//                                 <img src={instagram} style={{height:"40px",width:"40px"}} alt="instagram"/>
//                                 <img src={linkedIn} style={{height:"48px",width:"48px"}} alt="linkedIn"/>
//                             </div>  
//                         </div>
//                         <div className='subCarouselItem'>
//                             <Carousel interval={1000} controls={true}>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={egd_1} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={egd_2} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={egd_3} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={egd_4} alt='First slide' />
//                                 </Carousel.Item>
//                             </Carousel>
//                         </div>
//                     </Carousel.Item>
//                     <Carousel.Item className='custom-carousel-item' interval={1000}>
//                         <div className='carouselItem'>
//                             <img className='d-block' src={img3} alt='Third slide' />
//                         </div>
//                         <div className='text-container'>
//                             <p>15-18 Sept. 2023</p><br />
//                             <p>Bhopal Vigyan Mela</p>
//                             <div className='socialMedia'>
//                                 <img src={instagram} style={{height:"40px",width:"40px"}} alt="instagram"/>
//                                 <img src={linkedIn} style={{height:"48px",width:"48px"}} alt="linkedIn"/>
//                             </div>  
//                         </div>
//                         <div className='subCarouselItem'>
//                             <Carousel interval={1000} controls={true}>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={img3} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={img3} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={img3} alt='First slide' />
//                                 </Carousel.Item>
//                                 <Carousel.Item interval={1000}>
//                                     <img className='d-block' src={img3} alt='First slide' />
//                                 </Carousel.Item>
//                             </Carousel>
//                         </div>
//                     </Carousel.Item>
//                 </Carousel>
//             </center>
//         </div> */